.distance {
    position: absolute;
    bottom: 100px;
    right: 50px;
    display: block;
    color: #ff0000;
    font-family: 'IBM Plex Mono', monospace;
}

.vehicle {
    position: absolute;
    bottom: 80px;
    right: 50px;
    display: block;
    color: #ff0000;
    font-family: 'IBM Plex Mono', monospace;
}

.axis {
    position: absolute;
    bottom: 100px;
    left: 50px;
    display: block;
    color: #fff000;
    font-family: 'IBM Plex Mono', monospace;
}

.lineID {
    position: absolute;
    bottom: 80px;
    left: 50px;
    display: block;
    color: #fff000;
    font-family: 'IBM Plex Mono', monospace;
}

.stats {
    position: absolute;
    bottom: 300px;
    left: 50px;
    display: block;
    color: #fff000;
    font-family: 'IBM Plex Mono', monospace;
}
