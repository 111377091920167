.nav-tabs, .nav-pills {
  overflow-x: auto;
  white-space: nowrap;
  flex-wrap: inherit;
}

/* Specific styles for non-active links */
.nav-link {
  color: blue;
}

/* Specific styles for active links */
.link-active {
  color: black;
  background: orange;
}