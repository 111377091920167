.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  /* font-family: "Open Sans", sans-serif; */
  font-weight: 600;
}

.add-button {
  border: 1px solid skyblue; 
  background-color: rgba(0,0,0,0); 
  color: skyblue; 
  padding: 5px;
}